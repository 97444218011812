import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ReactGA from 'react-ga';

import Home from './home';

import './app.css';

function App() {

  const gaTrackingID =  'UA-79559806-1';

  ReactGA.initialize(gaTrackingID, {
    debug: false,
    titleCase: false
  });

  return (
    <div className="App">
      <div className="appBody">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>          
        </Router>
      </div>
    </div>
  );

}

export default App;
