import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { OpenInNew } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import logo from './images/logo.png';

const useStyles = makeStyles({
    media: {
        height: '18em',
    },
});

const translate = (key: string) => {
    if (key == 'home.title') {
        return 'IMPORTANT:We have migrated your learning experience to our new Learning Center platform.'
    }


/*


 

If you need to set up an account in the new Learning Center, please login using the same email address associated with your account here https://learningcenteriin.com/my-courses-access.

 

Once your account is set up you may then make a change to your email address in the new Learning Center.

 

If you have already created an account in the new Learning Center, please log in here https://www.learningcenteriin.com
*/

    if (key == 'home.subtitle') {
        return 'If you need to set up an account in the new Learning Center, please login using the same email address associated with your account.\nOnce your account is set up you may then make a change to your email address.';
    }

    if (key == 'home.subtitle2') {
        return 'If you have already created an account in the new Learning Center, please log in.';
    }

    if (key == 'home.login') {
        return 'Sign up here';
    }
    if (key == 'home.login2') {
        return 'Log in here';
    }
    if (key == 'home.copyright') {
        return '© integrativenutrition.com 2023. All rights reserved. ';
    }
    return key;
};

const Home = () => {
    const classes = useStyles();
    return (
        <Container>
        <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid direction='row' xs={12} style={{textAlign: "center"}}>
                <Card elevation={0}>
                    <CardContent>
                        <div>
                            <img aria-hidden="true" src={logo} />
                        </div>
                        <Typography variant="h5" component="h2">
                            {translate('home.title')}
                        </Typography>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            {translate('home.subtitle')}
                        </Typography>
                        <Button href="https://learningcenteriin.com/my-courses-access">
                            <OpenInNew style={{ paddingRight: '0.5em' }} />
                            {translate('home.login')}
                        </Button>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            {translate('home.subtitle2')}
                        </Typography>
                        <Button href="https://learningcenteriin.com/login">
                            <OpenInNew style={{ paddingRight: '0.5em' }} />
                            {translate('home.login2')}
                        </Button>
                    </CardContent>
                </Card>
                <Grid direction='column' xs={12} container spacing={1} justify="center" style={{textAlign: "center", height:"100%"}}>
                <Box m={1} pt={1}>
                <Card elevation={0} style={{textAlign: "center"}}>
                    <CardContent>
                        <Paper elevation={0}>
                            <Typography>{translate('home.copyright')}</Typography>
                        </Paper>
                    </CardContent>
                </Card>
                </Box>
                </Grid>
            </Grid>            
        </Grid>
        </Container>
    );
};

export default Home;
